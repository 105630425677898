'use client'

import React from 'react'
import cx from "classnames";
import Typography from '@/components/shared/Typography';
import { t } from '@/lib/helpers/directus';

function GetInput({ type, required = true, placeholder, value, onChange, name, id, className, style, label, params, ...rest }, withPeer = false) {
	return (
		<input
			required={required}
			type={type == 'date' ? 'text' : type}
			onFocus={(e) => e.target.type = type}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			name={name}
			id={id}
			className={cx(`${withPeer ? 'hidden peer' : ''} font-overpass text-base mb-1 py-1 px-2 bg-transparent outline-none text-secondary-100 placeholder:text-secondary-100 placeholder:opacity-50 flex-1 rounded-none`, className)}
			style={style}
			{...rest}
		/>
	);
}

export default function Input({ type, required = true, placeholder, value, onChange, name, id, className, style, label, params, ...rest }) {
	return (
		<>
			{type == 'checkbox' ? (
				<>
					<label htmlFor={id} className='w-6 h-6 float-left cursor-pointer mr-2'>
						{GetInput({ type, required, placeholder, value, onChange, name, id, className, style, label, params, ...rest }, true)}

						{/* Not checked */}
						<div className="w-full h-full rounded-md bg-primary-800 flex justify-center items-center peer-checked:hidden" />

						{/* Checked */}
						<div className="w-full h-full rounded-md bg-primary-800 justify-center items-center hidden peer-checked:flex">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-check2" viewBox="0 0 16 16">
								<path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
							</svg>
						</div>
					</label>
					{label && (
						<label htmlFor={id}>
							<Typography variant='p' className='w-fit text-primary-800 cursor-pointer'>
								{label}
							</Typography>
						</label>
					)}
				</>
			) : (
				GetInput({ type, required, placeholder, value, onChange, name, id, className, style, label, params, ...rest }, false)
			)}
		</>
	)
}
