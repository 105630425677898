'use client'

import React from 'react'
import cx from "classnames";
import { t } from '@/lib/helpers/directus';

export default function Select({ options, placeholder, value, onChange, name, id, className, style, params, ...rest }) {
	if (!options) return;

	return (
		<>
			<select
				placeholder={placeholder}
				defaultValue={value ?? 'select'}
				onChange={onChange}
				name={name}
				id={id}
				className={cx('font-overpass text-base mb-1 py-1 px-2 bg-transparent outline-none text-secondary-100 placeholder:text-secondary-100 placeholder:opacity-50 flex-1', className)}
				style={style}
				{...rest}
			>
				<option disabled value="select" className='bg-primary-800 bg-opacity-50 opacity-50'>
					{placeholder ?? t(params, 'select_option')}
				</option>

				{options.map((option, index) => (
					<option key={index} value={option.value ?? ''} className='text-secondary-100 bg-primary-800'>
						{option.label ?? ''}
					</option>
				))}
			</select>
		</>
	)
}
